import React from "react"
import PropTypes from "prop-types";
import { graphql } from "gatsby"
import Layout from "../layouts/layout"
import theme from "../theme/theme.yaml"
import Blog from "../components/blog/blog"
import SEO from "../components/seo/seo"

class BlogPage extends React.Component {
  render() {
    const {
      data: {
        posts: { edges: posts = [] },
        site: {
          siteMetadata: { title, description },
        },
      },
    } = this.props

    return (
      <React.Fragment>
        <SEO title={title} description={description} />
        <Layout>
          <div className="blogContainer">
            <Blog posts={posts} theme={theme} />
          </div>
        </Layout>

        <style jsx>{`
          .blogContainer {
            position: relative;
            background-color: ${theme.color.background.white_opac};
            z-index: 0;
            width: 95vw;
            max-width: ${`calc(${theme.text.maxWidth.desktop} *2)`};
            margin: ${`calc(${theme.header.height.homepage} + ${theme.space.xl})`} auto ${theme.space.xl};
          }
        `}</style>
      </React.Fragment>
    )
  }
}

export default BlogPage

BlogPage.propTypes = {
  data: PropTypes.object.isRequired
};

export const query = graphql`
  query {
    posts: allMarkdownRemark(
      filter: {
        frontmatter: { status: { eq: "public" } }
        fileAbsolutePath: { regex: "//blog/[0-9]+.*-/" }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          fileAbsolutePath
          frontmatter {
            author
            category
            description
            title
            date(formatString: "DD.MM.YYYY")
            status
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 360) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
/*


*/